import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SiteLoader from './views/components/SiteLoader';
import commonObj from './commonObj';
import service from '../src/services/admin'
import { message, ConfigProvider, /* theme */ } from 'antd';


const LayoutAdmin = React.lazy(() => import('./views/layout/Layout'));
const Login = React.lazy(() => import('./views/pages/Login'));

function App() {
	const [isLoggedin, setIsLoggedin] = useState(true);
	const [ajxRequesting, setAjxRequesting] = useState(false);

	useEffect(() => {
		setAjxRequesting(true);

		service.validateToken().then(res => {
			setIsLoggedin(true);
			commonObj.userType = res.data.type;
			commonObj.modules = res.data.modules;
			commonObj.name = res.data.name;
			commonObj.avatar = res.data.avatar;
			message.success(res.message);
		}).catch(err => {
			setIsLoggedin(false);
			window.localStorage.clear();
			message.error('Unauthorized. Login Again!!', 'error');
		}).finally(() => {
			setAjxRequesting(false);
		});
	}, []);


	return (
		<>
			<ConfigProvider
				theme={{
					// algorithm: theme.darkAlgorithm,
					token: {
						"borderRadius": 0
					}
				}}
			>
				<BrowserRouter basename="/">
					{
						!ajxRequesting
							? isLoggedin
								? <Suspense fallback={<SiteLoader />}><LayoutAdmin /></Suspense>
								: <Suspense fallback={<SiteLoader />}><Login /></Suspense>
							: <SiteLoader />
					}

				</BrowserRouter >
			</ConfigProvider >
		</>
	);
}

export default App;
